import React, {useState} from 'react';

// import './fonts/MarkOT-Bold.woff';
// import './fonts/MarkOT.woff'
import './app.scss';
import {ContentTile} from "./components/content-banner/content-tile";

function App() {

    const [demoConfig, setDemoConfig] = useState({ctaLabel : "Explore seasonal", bgColor : "#3B2B2F", headerColor:"white" })
    const [tileOuterWidth, setTileOuterWidth] = useState("25%");
    const [tileOuterHeight, setTileOuterHeight] = useState("512px");
    const [headerCopy, setHeaderCopy] = useState("Fall Flavors")

    const setProperties = (value:string, propName:string)=>{
        switch (propName) {
            case "headerCopy":
                setHeaderCopy(value);
                break;
            case "ctaLabel":
                setDemoConfig({ctaLabel: value, bgColor: demoConfig.bgColor, headerColor: demoConfig.headerColor})
                break;
            case "bgColor":
                setDemoConfig({ctaLabel: demoConfig.ctaLabel, bgColor:value, headerColor: demoConfig.headerColor})
                break;
            case "headerColor":
                setDemoConfig({headerColor:value, ctaLabel: demoConfig.ctaLabel, bgColor:demoConfig.bgColor})
                break;
            case "tileOuterWidth":
                setTileOuterWidth(value);
                break;
            case "tileOuterHeight":
                setTileOuterHeight(value);
        }
    }

  return (
    <div className="Apper">

        <ContentTile
           outerWidth={tileOuterWidth}
           outerHeight={tileOuterHeight}
           bgColor={demoConfig.bgColor}
           headerCopy={headerCopy}
           headerColor={demoConfig.headerColor}
           ctaLabel={demoConfig.ctaLabel}
           ctaURL={"https://www.keurig.com/beverages/seasonal/c/seasonal101"}
           upperImage={"/images/coffee-cup-autumn.png"}
           lowerImage={"/images/seasonal-fall-tastes.png"}
           backgroundImage={"images/seasonal-bg.jpg"}
      />

        <div className="dev-tools">

            <div className="input-group">
                <label htmlFor={"headerCopy"}>Header Copy </label>
                <input type="text" placeholder={headerCopy} name={"headerCopy"} onChange={(event)=>setProperties(event.target.value, "headerCopy")} />
            </div>

            <div className="input-group">
                <label htmlFor={"ctaLabel"}>CTA Label </label>
                <input type="text" name={"ctaLabel"} onChange={(event)=>setProperties(event.target.value, "ctaLabel")} />
            </div>

            <div className="input-group">
                <label htmlFor={"bgColor"}>BG Color </label>
                <input type="text" name={"bgColor"} onChange={(event)=>setProperties(event.target.value, "bgColor")} />
            </div>

            <div className="input-group">
                <label htmlFor={"headerColor"}>Header Color </label>
                <input type="text" name={"headerColor"} onChange={(event)=>setProperties(event.target.value, "headerColor")} />
            </div>

            <div className="input-group">
                <label htmlFor={"tileWidth"}>Tile Width </label>
                <input type={"range"} min={"100"} max={"1400"} onChange={(event)=>setProperties(event.target.value+"px", "tileOuterWidth")}/>
                <input type="text" name={"tileWidth"} onChange={(event)=>setProperties(event.target.value, "tileOuterWidth")} />
            </div>

            <div className="input-group">
                <label htmlFor={"tileWidth"}>Tile Height </label>
                <input type={"range"} min={"300"} max={"1200"} onChange={(event)=>setProperties(event.target.value+"px", "tileOuterHeight")} />
                <input type="text" name={"tileWidth"} onChange={(event)=>setProperties(event.target.value, "tileOuterHeight")} />
            </div>

        </div>
    </div>
  );
}

export default App;
