import React, {ReactElement, useEffect, useRef, useCallback, RefObject, DOMElement, useState} from "react";
import styled from "styled-components";

interface Ibutton{
    classes : string;
    label : string;
    linkTo : string;
}

export function Button(props:Ibutton){

    return(
        <a href={props.linkTo} className={`k-btn ${props.classes}`}>
            {props.label}
        </a>
    )

}