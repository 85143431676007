import React, {ReactElement, useEffect, useRef, useCallback, RefObject, DOMElement, useState} from "react";
import styled from "styled-components";
import {Button} from "../button/button";
import '../button/button.scss'


interface IcontentTile{
    outerWidth : string;
    outerHeight : string;
    upperImage : string;
    backgroundImage : string;
    ctaLabel : string;
    ctaURL : string;
    bgColor : string;
    headerCopy : string;
    headerColor : string;
    lowerImage : string;
 }



export function ContentTile(props:IcontentTile){

    const containerRef:RefObject<any> = useRef<HTMLDivElement>();

    const [componentType, setComponentType] = useState("ccc");

    const [componentConfig, setComponentConfig] = useState({ ctaLabel : ""})

    const PromoContainer = styled.div`
      background-color:${props.bgColor};
    `



    useEffect(() => {

        //setComponentConfig({ctaLabel: props.ctaLabel})

        if(containerRef){
            const boo = containerRef.current as HTMLDivElement;
            console.log(boo.getBoundingClientRect());
            setComponentType("ccccc121")
        }
    });

    return(
        <div style={{width : props.outerWidth, height: props.outerHeight}} className={`content-tile ${componentType}`} ref={containerRef}>
            <div className={"upper-element-container"}>
                <img className="upper-element" src={props.upperImage}/>
            </div>
            <PromoContainer  className={"promo-message"} style={{}}>
                <h3 style={{"color":props.headerColor}}>{props.headerCopy}</h3>
                <Button classes="promo-cta" label={props.ctaLabel} linkTo={props.ctaURL} />
            </PromoContainer>
            <div className={"bg-image-container"}>
                <img className={"lower-image"} src={props.lowerImage}/>
                <img className={"bg-image"} src={props.backgroundImage}/>
            </div>
        </div>
    )
}